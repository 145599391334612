import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice:  Stone Cleans & Rope Climbs`}</p>
    <p>{`Then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`5-Box Jumps (24/20) (RX+=30/24)`}</p>
    <p>{`4-Stone Cleans (108/60) (RX+=150/80)`}</p>
    <p>{`3-Tire Flips (M-large tire/W-small tire) (RX+=large tire for men &
women)`}</p>
    <p>{`2-Wall Walks`}</p>
    <p>{`1-Rope Climb to 15′ (RX+=legless)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      